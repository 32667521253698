//格式化习题数据
export function formatExercisesInfo(exercisesInfo, openType) {
    let newData = Object.assign({}, exercisesInfo);
    let showData = {};
    if (openType === 1) {
        if (newData.exercisesId) {
            showData = newData.exercisesInfo;
            if (showData.exercisesType === "SC" || showData.exercisesType === "MC") {
                showData.questionOption = showData.exercisesBody.split('\@')
                showData.correctAnswerArr = showData.correctAnswer.split(',')
            }
            if (showData.exercisesType === "FB") {
                showData.exercisesBody = parseInt(showData.exercisesBody)
            }
            showData.enclosure = showData.exercisesAccessoryUrl
        } else {
            newData.exercisesTitle = newData.courseworkItemTitle;
            showData = newData;
            showData.enclosure = newData.fileUrl
        }
    } else if (openType === 2) {
        showData = newData;
        showData.enclosure = showData.exercisesAccessoryUrl;
        if (showData.exercisesType === "SC" || showData.exercisesType === "MC") {
            showData.questionOption = showData.exercisesBody.split('\@')
            showData.correctAnswerArr = showData.correctAnswer.split(',')
        }
        if (showData.exercisesType === "FB") {
            showData.exercisesBody = parseInt(showData.exercisesBody)
        }
    } else if (openType === 3) { // 老师批改作业
        showData = newData;
        if (showData.exercisesType === "SC" || showData.exercisesType === "MC") {
            showData.questionOption = showData.exercisesBody.split('\@')
            showData.correctAnswerArr = showData.correctAnswer.split(',')
        }
        if (showData.exercisesType === "FB") {
            showData.exercisesBody = parseInt(showData.exercisesBody)
        }
        if (showData.workType === "SAQ") {
            showData.exercisesTitle = showData.courseworkItemTitle
        }
        showData.enclosure = newData.fileUrl || newData.exercisesAccessoryUrl
    }else if (openType === 4) { // 老师批改试卷
        showData = newData;
        if (showData.exercisesType === "SC" || showData.exercisesType === "MC") {
            showData.questionOption = showData.exercisesBody.split('\@')
            showData.correctAnswerArr = showData.correctAnswer.split(',')
        }
        if (showData.exercisesType === "FB") {
            showData.exercisesBody = parseInt(showData.exercisesBody)
        }
        if (showData.workType === "SAQ") {
            showData.exercisesTitle = showData.courseworkItemTitle
        }
        if(showData.answerContent){
            showData.answerVo = {};
            showData.answerVo.answerContentAry =showData.answerContent.split(",");
        }
        if(showData.errorBody){
            showData.answerVo.errorBody = showData.errorBody.split(",");
            showData.answerVo.errorBodyAry = showData.errorBody.split(",");
        }
        showData.enclosure = newData.fileUrl || newData.exercisesAccessoryUrl
    }
    if(exercisesInfo.answerVo && openType !== 4){
        showData.answerVo = exercisesInfo.answerVo;
        if(showData.answerVo.answerContent){
            showData.answerVo.answerContentAry = showData.answerVo.answerContent.split(",");
        }
        if(showData.answerVo.errorBody){
            showData.answerVo.errorBodyAry = showData.answerVo.errorBody.split(",");
        }

    }
    return showData;
}

//判断是否为url
export function isUrl(str) {
    var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i');
    return v.test(str);
}

//秒转分钟格式化
export function formatSeconds(value) {
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

    let res = '';
    if(h !== '00') res += `${h}小时`;
    if(m !== '00') res += `${m}分钟`;
    res += `${s}秒`;
    return res;
  }

export function hmsFormatDate(data){
    var d = new Date(data);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate();
    var hour = d.getHours()< 10 ? "0" + d.getHours():"" + d.getHours();
    var minutes = d.getMinutes()<10?"0"+d.getMinutes():""+d.getMinutes();
    var seconds = d.getSeconds()<10?"0"+d.getSeconds():""+d.getSeconds();
    return year + "-" + month + "-" + day + " " +hour + ":" + minutes + ":" + seconds;
}
