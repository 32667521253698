const avTypeAry = ["3gp","asf","avi","dat","dv","flv","f4v","gif","m2t","m3u8","m4v","mj2","mjepg","mkv","mov","mp4","mpe","mpg","mpeg","mts","ogg","qt","rm","rmvb","swf","ts","vob","wmv","webm"]
const audioTypeAry = ["aac","ac3","acm","amr","ape","caf","flac","m4a","mp3","ra","wav","wma"]
const officeTypeAry = ["docx", "doc", "xls", "xlsx", "ppt", "pptx"]
const imageTypeAry = ["jpg", "jpeg", "png", "gif", "bmp", "ico", "raw"]
const pdfTypeAry = ["pdf"]

const fileTypeObj = {
    avTypeAry:avTypeAry,
    audioTypeAry:audioTypeAry,
    officeTypeAry:officeTypeAry,
    imageTypeAry:imageTypeAry,
    pdfTypeAry:pdfTypeAry
}
//获取文件后缀
function checkFileType(fileName){
    let fileNameSuffix = fileName.match(/^(.*)(\.)(.{1,8})$/)[3];
    fileNameSuffix = fileNameSuffix.toLowerCase();
    return fileNameSuffix;
}
//匹配文件后缀
function isCon(arr, val){ 
    for(var i=0; i<arr.length; i++){ 
        if(arr[i] == val) 
            return true; 
        }
            return false; 
}
//判断文件播放类型 avTypeAry:视频，audioTypeAry：音频 ,officeTypeAry:office文件，pdfTypeAry：pdf
export function filePlayType(fileName){
    //获取文件后缀名
    let fileSuffix = checkFileType(fileName)
    //比较
    for(let key in fileTypeObj){
        let fileTypeAry = fileTypeObj[key];
        if(fileTypeAry  instanceof Array){
            let isSelect = isCon(fileTypeAry,fileSuffix)
            if(isSelect){
                return key;
            }
        }
      }
}