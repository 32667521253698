<template>
    <!-- 习题附件 -->
    <div v-if="analysisFileUrl!=''">
        <el-row :gutter="20">
            <el-col :span="dynamicComputingColSpan(item)" v-for="(item,index) in fileInfoAry">
                <el-image v-if="(checkExercisesResourceType(item.fileOriginalName))=='imagePlay'"
                    style="width: 100px; height: 100px"
                    :src="item.aliVideoOssUrlAry[0]"
                    :preview-src-list="item.aliVideoOssUrlAry"
                    class="analysisImg"
                >
                </el-image>
                <el-card  v-if="(checkExercisesResourceType(item.fileOriginalName))=='aliAVPlay'" style="width: 100%;" shadow="always">
                    <div slot="header" style="font-size:50px;text-align:center;cursor:pointer" @click="openVideoWhindow(item)">
                        <i class="el-icon-video-play"  style=""></i>
                    </div>
                    <div style="text-align:center;cursor:pointer" @click="openVideoWhindow(item)">
                        <span class="lineText">{{item.fileOriginalName}}</span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    <!--    视频播放-->
        <el-dialog
        :visible.sync="videoWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
            <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />
        </el-dialog>
    </div>
</template>
<script>
import {isUrl} from '@/utils/userCenterUtil'
import {audioImg} from '@/api/index'
import {filePlayType} from '@/utils/fileUtil'
import VueAliplayerV2 from 'vue-aliplayer-v2';
export default {
    name:"exercisesEnclosure",
    props: {
        fileUrl:{
            type:String,
            default(){
                return '';
            }
        }
    },
    data(){
        return{
            urlDataAry:[],
            thirdPartyIdAry:[],
            fileInfoAry:[],
            videoWhindow:false,
            videoOptions:{},
        }
    },
    created(){

    },
    components:{
        VueAliplayerV2
    },
    computed: {
        analysisFileUrl(){
            let fileUrlStr = this.fileUrl;
            let fileUrlAry = fileUrlStr.split(",");
            fileUrlAry.forEach(url=>{
                if(typeof(url)!='undefined'&&url!=''){
                    let isUrlData = isUrl(url);
                    if(isUrlData){
                        this.urlDataAry.push(url)
                    }else{
                        this.thirdPartyIdAry.push(url)
                    }
                }
            })
            if(this.thirdPartyIdAry.length>0){
                this.findThirdPartyResourceInfo();
            }
            if(this.thirdPartyIdAry.length==0&&this.urlDataAry.length==0){
                return ""
            }else{
                return this.fileUrl;
            }
        }
    },
    updated() {

    },
    methods:{
        findThirdPartyResourceInfo(){
            if(this.thirdPartyIdAry.length>0){
                let queryData = [];
                this.thirdPartyIdAry.forEach(tpId=>{
                    if(tpId!=''&&tpId.length>1){
                        queryData.push({"thirdPartyStorageId":tpId})
                    }
                })
                if(queryData.length>0){
                    audioImg({queryDataList:queryData}).then(res=>{
                       if(res.code=="0"&&res.data.length>0){
                           res.data.forEach(resData=>{
                               if(resData.aliVideoOssUrl){
                                   resData.aliVideoOssUrlAry = resData.aliVideoOssUrl.split(",")
                               }

                           })
                           this.fileInfoAry = res.data;
                       }
                    })
                }
            }
        },
        checkExercisesResourceType(fileName){
            let fileType = filePlayType(fileName);
            switch (fileType){
                case 'avTypeAry':
                    return "aliAVPlay"
                    break;
                case 'audioTypeAry':
                    return "aliAVPlay"
                    break;
                case 'officeTypeAry':
                    return "imagePlay"
                    break;
                case 'imageTypeAry':
                    return "imagePlay"
                    break;
                case 'pdfTypeAry':
                    return "imagePlay"
                    break;
                default:
                    return ""
                    break;
            }
        },
        openVideoWhindow(playData){
            this.videoOptions.vid = playData.thirdPartyStorageId
            this.videoOptions.playauth = playData.playAuth;
            this.videoWhindow = true;
        },
        dynamicComputingColSpan(data){
            if((this.checkExercisesResourceType(data.fileOriginalName))=='imagePlay'){
                return 4
            }else if((this.checkExercisesResourceType(data.fileOriginalName))=='aliAVPlay'){
                return 12
            }else{
                0
            }
        }
    },
    watch:{

    }
}
</script>
<style scoped>
.lineText{
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 1;
     overflow: hidden;
}
.analysisImg img{
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
