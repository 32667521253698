<template>
  <!-- 教师批阅评语 -->
  <div >
    <div class="teacherComments">
      <p>教师评语:</p>
      <el-input type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="resultEvaluate"
      ></el-input>
    </div>
  </div>
</template>
<script>
import {formatExercisesInfo} from "@/utils/userCenterUtil";

export default {
  name:"teacherCorrection",
  props: {
    //习题数据
    exercisesInfo:{
      type:Object,
      required: true,
      default(){
        return {}
      },
    }
  },
  data(){
    return{
      resultEvaluate: ""
    }
  },
  methods:{
    getCommentData(){
      return {item:this.exercisesInfo, resultEvaluate: this.resultEvaluate}
    }
  },
  mounted() {
  }
}
</script>
<style>
  .teacherComments{
    display: flex;
    align-items: center;
  }
  .teacherComments>p{
    width: 100px;
    color: red;
    margin: 26px 0;
  }
</style>
