<template>
  <div class="topicFontSize">
    <div class="topicBorder" style="border:0px">
      <p class="topicTitle">
        <span style="color: red">{{ exercisesListIndex }}.</span>
        <span style="color: red" class="exercisesScoreMR"
              v-if="useFormatExercisesInfo.scoreNumber">({{ useFormatExercisesInfo.scoreNumber }}分):</span>
        <span style="font-size: 16px">{{ useFormatExercisesInfo.exercisesTitle }}</span>
      </p>
      <exercisesEnclosure :fileUrl="useFormatExercisesInfo.enclosure"></exercisesEnclosure>
      <div v-if="!useFormatExercisesInfo.answerVo||!useFormatExercisesInfo.answerVo.answerContentAry" v-for="(item, index) in useFormatExercisesInfo.questionOption"
           :key="index"
           class="chooseSpan">
        <div>
<!--
  *****提交时需先获取value, 将value按照'-'分割, 提取前面的数字；或使用parseInt函数处理一下值，提取value前面的数字
  -->
          <el-radio v-model="sCRadio" :label="index+1" style="padding:10px">{{ getLetter(index) }}：{{
              item
            }}
          </el-radio>
        </div>
      </div>

      <div v-if="useFormatExercisesInfo.answerVo&&useFormatExercisesInfo.answerVo.answerContentAry" v-for="(item, index) in useFormatExercisesInfo.questionOption"
           :key="index"
           class="chooseSpan">
        <div>
          <el-radio v-model="useFormatExercisesInfo.answerVo.answerContentAry[0]" :label="index+1+''"
                    :class="checkItemColor(useFormatExercisesInfo,index+1)==0?'textColorCheckboxError':''"
                    style="padding:10px">
            <span
                :class="checkItemColor(useFormatExercisesInfo,index+1)==0?'textColorError':checkItemColor(useFormatExercisesInfo,index+1)==1?'textColorRight':''">{{ getLetter(index) }}：{{
                item
              }}</span>
          </el-radio>
        </div>
      </div>
      <rightAndAnalysis v-if="isShowRightAndAnalysis" :exercisesInfo="useFormatExercisesInfo"></rightAndAnalysis>
      <div class="teacherPGScore" v-if="isShowTeacherMCDecide">
        <p>教师批改:</p>
        <el-radio-group
            v-model="mResult">
          <el-radio label="correct">正确</el-radio>
          <el-radio label="error">错误</el-radio>
        </el-radio-group>
      </div>
      <teacherComments v-if="isShowTeacherComments" :exercisesInfo="useFormatExercisesInfo"></teacherComments>
      <div v-if="isShowTeacherCorrection">
        <div class="teacherComments">
          <p>教师评语:</p>
          <el-input type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="resultEvaluate"
          ></el-input>
        </div>
      </div>
      <div class="teacherPGScore" v-if="isShowTeacherMCDecideScore">
        <p>试题得分:</p>
        <el-input  v-model="resultScore" placeholder="请输入得数"
                  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import {formatExercisesInfo} from '@/utils/userCenterUtil'
import rightAndAnalysis from './rightAndAnalysis.vue'
import exercisesEnclosure from './exercisesEnclosure.vue'
import teacherComments from './teacherComments.vue'
import teacherCorrection from './teacherCorrection.vue'

export default {
  name: "exercisesSC",
  props: {
    //习题数据
    exercisesInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    //打开方式 1作业，2考试
    openType: {
      type: Number,
      required: true//必传，
    },
    exercisesListIndex: {
      type: Number,
      default: 1
    },
    //是否显示答案与解析,默认false
    isShowRightAndAnalysis: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //是否显示教师批语,默认false
    isShowTeacherComments: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 是否显示教师批改
    isShowTeacherCorrection: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //  显示教师批改多选结果
    isShowTeacherMCDecide: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //  试题得分
    isShowTeacherMCDecideScore: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  data() {
    return {
      sCRadio: '',
      resultScore: '',
      mResult: '',
      resultEvaluate: "",
      itemObj:{},
    }
  },
  created() {

  },
  components: {
    rightAndAnalysis,
    exercisesEnclosure,
    teacherComments,
    teacherCorrection
  },
  computed: {
    useFormatExercisesInfo() {
      let progressAnswerContent = this.exercisesInfo.progressAnswerContent
      if (progressAnswerContent) {
        this.sCRadio = progressAnswerContent;
      }
      let formatExercisesInfoResult = formatExercisesInfo(this.exercisesInfo, this.openType)
      this.mResult = formatExercisesInfoResult.mResult;
      this.resultEvaluate = formatExercisesInfoResult.resultEvaluate;
      this.resultScore = formatExercisesInfoResult.resultScore?formatExercisesInfoResult.resultScore:0;
      return formatExercisesInfoResult;
    }
  },
  updated() {

  },
  methods: {
    getLetter(index) {
      return String.fromCharCode(65 + index)
    },
    //选差选项颜色 0红色，1绿色，2无色
    checkItemColor(exercisesInfo, index) {
      let answerVoData = exercisesInfo.answerVo;
      let result = 2
      if (answerVoData) {
        let answerContentAry = answerVoData.answerContentAry;
        let errorBodyAry = answerVoData.errorBodyAry;
        let correctAnswerArr = exercisesInfo.correctAnswerArr;
        if (correctAnswerArr && correctAnswerArr.length > 0) {
          correctAnswerArr.forEach(caa => {
            if (caa == index) {
              result = 1
            }
          })
        }
        if (result == 2) {
          answerContentAry.forEach(act => {
            if (act == index) {
              if(errorBodyAry!==undefined){
                errorBodyAry.forEach(eba => {
                  if (eba == index) {
                    result = 0
                  }
                })
                if (result == 2) {
                  result = 1
                }
              }
            }
          })
        }
      }
      return result
    },
    getCorrection() {
      return {item: this.exercisesInfo, resultEvaluate: this.resultEvaluate}
    },
    getScoreData() {
      return {item: this.exercisesInfo, resultScore: this.resultScore}
    },
    getmResultData() {
      return {item: this.exercisesInfo, mResult: this.mResult}
    }
  },
  watch: {
    sCRadio(val, oldVal) {
      if(this.exercisesInfo&&this.exercisesInfo.uid){
        let obj={};
        obj=this.exercisesInfo;
        obj.answerContent=parseInt(val);
        obj.exercisesListIndex = this.exercisesListIndex;
        this.$emit('pushData', obj);
      }else{
        let pushData = {};
        pushData.id = this.exercisesInfo.id;
        pushData.answerContent = parseInt(val);
        pushData.exercisesListIndex = this.exercisesListIndex;
        pushData.exercisesType = "SC";
        this.$emit('pushData', pushData)
      }
    },
    resultEvaluate(val,oldVal){
      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.resultEvaluate=val
      this.$emit('evaluateData', this.itemObj)
    },
    resultScore(val,oldVal){

      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.resultScore=val
      this.$emit('evaluateData', this.itemObj)
      if(val&&val>this.useFormatExercisesInfo.scoreNumber){
        this.resultScore=this.useFormatExercisesInfo.scoreNumber
      }
    },
    mResult(val,oldVal){
      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.mResult=val
      this.$emit('evaluateData', this.itemObj)
    },
  }
}
</script>
<style scoped>
.textColorRight {
  color: #409EFF;
  font-weight: bold;
}
.textColorError {
  color: red
}

.textColorCheckboxError {
  border: 1px solid red;
}

.exercisesScoreMR {
  margin: 0 12px;
}

.teacherPGScore {
  display: flex;
  align-items: center;
}

.teacherPGScore > p {
  width: 100px;
  color: red;
  margin: 14px 0;
}
</style>
