<template>
    <div>
        <div class="topicBorder" style="border:0px">
          <p class="topicTitle">
             <span style="color: red">{{exercisesListIndex}}.</span>
            <span style="color: red" class="exercisesScoreMR" v-if="useFormatExercisesInfo.scoreNumber">({{useFormatExercisesInfo.scoreNumber}}分):</span>
            <span style="font-size: 16px">{{ useFormatExercisesInfo.exercisesTitle }}</span>
          </p>
          <exercisesEnclosure :fileUrl="useFormatExercisesInfo.enclosure"></exercisesEnclosure>
            <el-input
                v-if="!useFormatExercisesInfo.answerVo||!useFormatExercisesInfo.answerVo.answerContentAry"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 10 }"
                v-model="sAQText"
            ></el-input>
          <el-input
              v-if="useFormatExercisesInfo.answerContent"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              v-model="useFormatExercisesInfo.answerContent"
          ></el-input>
<!--            <div v-if="useFormatExercisesInfo.answerVo&&useFormatExercisesInfo.answerVo.answerContentAry" :class="checkSAQAnswerIsRightOrError(useFormatExercisesInfo)" style="padding:10px">-->
<!--                {{useFormatExercisesInfo.answerVo.answerContent}}-->
<!--            </div>-->

            <rightAndAnalysis v-if="isShowRightAndAnalysis" :exercisesInfo="useFormatExercisesInfo"></rightAndAnalysis>
            <div class="teacherPGScore" v-if="isShowTeacherMCDecide">
              <p>教师批改:</p>
              <el-radio-group
                  v-model="mResult">
                <el-radio label="correct">正确</el-radio>
                <el-radio label="error">错误</el-radio>
              </el-radio-group>
            </div>
            <teacherComments v-if="isShowTeacherComments" :exercisesInfo="useFormatExercisesInfo"></teacherComments>
            <div v-if="isShowTeacherCorrection">
              <div class="teacherComments">
                <p>教师评语:</p>
                <el-input type="textarea"
                          :rows="2"
                          placeholder="请输入内容"
                          v-model="resultEvaluate"
                ></el-input>
              </div>
            </div>
          <div class="teacherPGScore" v-if="isShowTeacherMCDecideScore">
            <p>试题得分:</p>
            <el-input  v-model="resultScore" placeholder="请输入得数" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
          </div>
        </div>
    </div>
</template>
<script>
import {formatExercisesInfo} from '@/utils/userCenterUtil'
import rightAndAnalysis from './rightAndAnalysis.vue'
import exercisesEnclosure from './exercisesEnclosure.vue'
import teacherComments from './teacherComments.vue'
import teacherCorrection from './teacherCorrection.vue'
export default {
    name:"exercisesSAQ",
    props: {
        //习题数据
        exercisesInfo:{
            type:Object,
            required: true,
            default(){
                return {}
            },
        },
        //打开方式 1作业，2考试
        openType:{
            type:Number,
            required: true//必传，
        },
        exercisesListIndex:{
            type:Number,
            default:1
        },
        //是否显示答案与解析,默认false
        isShowRightAndAnalysis:{
            type:Boolean,
            default(){
                return false;
            }
        },
        //是否显示教师批语,默认false
        isShowTeacherComments:{
            type:Boolean,
            default(){
                return false;
            }
        },
      // 是否显示教师批改
      isShowTeacherCorrection: {
        type: Boolean,
        default() {
          return false;
        }
      },
      //  显示教师批改简答结果
      isShowTeacherMCDecide: {
        type: Boolean,
        default() {
          return false;
        }
      },
      //  试题得分
      isShowTeacherMCDecideScore: {
        type: Boolean,
        default() {
          return false;
        }
      },
    },
    data(){
        return{
          sAQText:"",
          mResult:'',
          resultScore:'',
          resultEvaluate: "",
          itemObj:{},
        }
    },
    created(){

    },
    components:{
        rightAndAnalysis,
        exercisesEnclosure,
        teacherComments,
        teacherCorrection
    },
    computed: {
        useFormatExercisesInfo(){
            let progressAnswerContent = this.exercisesInfo.progressAnswerContent
            if(progressAnswerContent){
                this.sAQText = progressAnswerContent;
            }
          let formatExercisesInfoResult = formatExercisesInfo(this.exercisesInfo, this.openType)
          this.mResult = formatExercisesInfoResult.mResult;
          this.resultEvaluate = formatExercisesInfoResult.resultEvaluate;
          this.resultScore = formatExercisesInfoResult.resultScore;
          return formatExercisesInfoResult;
        }
    },
    updated() {

    },
    methods:{
        checkSAQAnswerIsRightOrError(exercisesInfo){
            if(exercisesInfo.answerVo.mResult=="error"){
                return "textColorError"
            }else if(exercisesInfo.answerVo.mResult=="correct"){
                return "textColorRight"
            }else{
                return "";
            }

        },
      getCorrection() {
        return {item: this.exercisesInfo, resultEvaluate: this.resultEvaluate}
      },
      getScoreData(){
        return {item:this.exercisesInfo, resultScore: this.resultScore}
      },
      getmResultData(){
        return {item:this.exercisesInfo, mResult: this.mResult}
      }
    },
    watch:{
      answerCheck(item){
      },
      sAQText(val, oldVal){
        if(this.exercisesInfo&&this.exercisesInfo.uid){
          let obj={};
          obj=this.exercisesInfo;
          obj.answerContent=val;
          obj.exercisesListIndex = this.exercisesListIndex;
          this.$emit('pushData', obj);
        }else{
          let pushData = {};
          pushData.id = this.exercisesInfo.id;
          pushData.answerContent = val;
          pushData.exercisesListIndex = this.exercisesListIndex;
          pushData.exercisesType = "SAQ";
          this.$emit('pushData', pushData)
        }
      },
      resultEvaluate(val,oldVal){
        this.itemObj=this.useFormatExercisesInfo;
        this.itemObj.resultEvaluate=val
        this.$emit('evaluateData', this.itemObj)
      },
      resultScore(val,oldVal){
        this.itemObj=this.useFormatExercisesInfo;
        this.itemObj.resultScore=val
        this.$emit('evaluateData', this.itemObj)
      },
      mResult(val,oldVal){
        this.itemObj=this.useFormatExercisesInfo;
        this.itemObj.mResult=val
        this.$emit('evaluateData', this.itemObj)
      },
    }
}
</script>

<style scoped>
.textColorRight{
    color:#49B151;
  font-weight: bold;
}
.textColorError{
    color:red;
    border:1px solid red;
}
.exercisesScoreMR{
  margin: 0 12px;
}
.teacherPGScore{
  display: flex;
  align-items: center;
}
.teacherPGScore>p{
  width: 100px;
  color: red;
  margin: 14px 0;
}
</style>
