<template>
    <!-- 教师批语 -->
    <div style="margin-top:10px" v-if="(exercisesInfo.answerVo&&exercisesInfo.answerVo.mComments)">
        <el-card shadow="always">
            <div>
                <p class="analysis">
                <span></span>
                <span>教师批语</span>
                </p>
                <p>
                {{ exercisesInfo.answerVo.mComments }}
                </p>
            </div>
        </el-card>

    </div>
</template>
<script>
export default {
    name:"teacherComments",
    props: {
        //习题数据
        exercisesInfo:{
            type:Object,
            required: true,
            default(){
                return {}
            },
        }
    },
    data(){
        return{
            
        }
    },
    created(){

    },
    components:{

    },
    computed: {

    },
    updated() {
        
    },
    methods:{

    }
}
</script>