<template>
  <div>
    <div class="topicBorder" style="border:0px">
      <p class="topicTitle">
        <span style="color: red">{{ exercisesListIndex }}.</span>
        <span style="color: red" class="exercisesScoreMR" v-if="useFormatExercisesInfo.scoreNumber">({{useFormatExercisesInfo.scoreNumber}}分):</span>
        <span>{{ useFormatExercisesInfo.exercisesTitle }}</span>
      </p>
      <exercisesEnclosure :fileUrl="useFormatExercisesInfo.enclosure"></exercisesEnclosure>
      <div v-if="!useFormatExercisesInfo.answerVo||!useFormatExercisesInfo.answerVo.answerContentAry">
        <el-input v-model="fBInput[index]" class="tiankongInput" style="padding:10px;"
                  v-for="i,index in useFormatExercisesInfo.exercisesBody" :key="index"></el-input>
      </div>
      <div v-if="useFormatExercisesInfo.answerVo&&useFormatExercisesInfo.answerVo.answerContentAry">
        <div
            v-for="(i,index) in useFormatExercisesInfo.answerVo.answerContentAry"
            :key="index"
        >

          <el-input
              :class="checkFBAnswerIsRightOrError(useFormatExercisesInfo,index+1)==0?'tiankongInput textColorError':'tiankongInput'"
              style="padding:10px;"
              type="text"
              :value="i"
          ></el-input>
          <el-radio-group
              v-if="isShowTeacherDecide"
              v-model="mResult[index]">
            <el-radio label="correct">正确</el-radio>
            <el-radio label="error">错误</el-radio>
          </el-radio-group>
        </div>
      </div>
      <rightAndAnalysis v-if="isShowRightAndAnalysis" :exercisesInfo="useFormatExercisesInfo"></rightAndAnalysis>
      <teacherComments v-if="isShowTeacherComments" :exercisesInfo="useFormatExercisesInfo"></teacherComments>
      <div v-if="isShowTeacherCorrection">
        <div class="teacherComments">
          <p>教师评语:</p>
          <el-input type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="resultEvaluate"
          ></el-input>
        </div>
      </div>
      <div class="teacherPGScore" v-if="isShowTeacherMCDecideScore">
        <p>试题得分:</p>
        <el-input  v-model="resultScore" placeholder="请输入得数" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import {formatExercisesInfo} from '@/utils/userCenterUtil'
import rightAndAnalysis from './rightAndAnalysis.vue'
import exercisesEnclosure from './exercisesEnclosure.vue'
import teacherComments from './teacherComments.vue'
import teacherCorrection from './teacherCorrection.vue'

export default {
  name: "exercisesFB",
  props: {
    //习题数据
    exercisesInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    //打开方式 1作业，2考试 3.老师批改作业
    openType: {
      type: Number,
      required: true//必传，
    },
    exercisesListIndex: {
      type: Number,
      default: 1
    },
    //是否显示答案与解析,默认false
    isShowRightAndAnalysis: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //是否显示教师批语,默认false
    isShowTeacherComments: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 是否显示教师批改
    isShowTeacherCorrection: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //  显示教师批改填空单选
    isShowTeacherDecide: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //  试题得分
    isShowTeacherMCDecideScore: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  data() {
    return {
      fBInput: [],
      // 老师批阅: 是否正确
      mResult: [],
      resultScore:'',
      resultEvaluate: "",
      itemObj:{},
    }
  },
  created() {

  },
  components: {
    rightAndAnalysis,
    exercisesEnclosure,
    teacherComments,
    teacherCorrection
  },
  computed: {
    useFormatExercisesInfo() {
      let progressAnswerContent = this.exercisesInfo.progressAnswerContent
      if (progressAnswerContent) {
        this.fBInput = progressAnswerContent.split(",");
      }
      let useFormatExercisesInfo = formatExercisesInfo(this.exercisesInfo, this.openType);

      if(useFormatExercisesInfo && useFormatExercisesInfo.answerVo && useFormatExercisesInfo.answerVo.answerContentAry){
        let answerContentAry = useFormatExercisesInfo.answerVo.answerContentAry;
        this.mResult = Array.from({length: answerContentAry.length}, () => (""))
        let mResult=[];
        useFormatExercisesInfo.answerVo.answerContentAry.forEach((item,index)=>{
          if(useFormatExercisesInfo.answerVo.errorBody&&useFormatExercisesInfo.mResult=='error'){
            let has=useFormatExercisesInfo.answerVo.errorBody.includes(index+1+"");

            if(has){
              mResult[index]='error'
            }else{
              mResult[index]='correct'
            }
          }else{
            // mResult[index]='correct'
          }
        });
        this.mResult=mResult
        // if(useFormatExercisesInfo.mResult){
        //   let errorBody=useFormatExercisesInfo.errorBody
        //   this.mResult.forEach((item,index)=>{
        //     errorBody.forEach((item1,index1)=>{
        //       if(index1-1===index){
        //         item='error'
        //       }else{
        //         item='correct'
        //       }
        //     })
        //   })
        // }
        this.resultEvaluate = useFormatExercisesInfo.resultEvaluate;
        this.resultScore = useFormatExercisesInfo.resultScore;
      }
      return useFormatExercisesInfo;
    }
  },
  updated() {

  },
  methods: {
    //0错误，1正常
    checkFBAnswerIsRightOrError(exercisesInfo, index) {
      let result = 1
      if (exercisesInfo.answerVo.errorBodyAry) {
        exercisesInfo.answerVo.errorBodyAry.forEach(eb => {
          if (eb == index) {
            result = 0;
          }
        })
      }
      return result;
    },
    getCorrection() {
      return {item: this.exercisesInfo, resultEvaluate: this.resultEvaluate}
    },
    getFBCheckData(){
      return {item:this.exercisesInfo, mResult: this.mResult}
    },
    getScoreData(){
      return {item:this.exercisesInfo, resultScore: this.resultScore}
    },
  },
  watch: {
    fBInput(val, oldVal) {
      console.log(val,'888888888888')
      if(this.exercisesInfo&&this.exercisesInfo.uid){
        let pushData = {};
        pushData = this.exercisesInfo;
        //判断是否全为空格
        let newfBInput = [];
        let emptyNum = 0;
        for (let i = 0; i < val.length; i++) {
          let fb = val[i];
          if (typeof (fb) != 'undefined' && fb.trim() != "") {
            newfBInput.push(fb)
          } else {
            newfBInput.push("")
            emptyNum++;
          }
        }
        if (emptyNum != 0 && emptyNum == (newfBInput.length)) {
          pushData.answerContent = null;
        } else {
          let inputNumber = this.useFormatExercisesInfo.exercisesBody;
          if (newfBInput.length < inputNumber) {
            for (let i = 0; i < (inputNumber - (newfBInput.length)); i++) {
              newfBInput.push("");
            }
          }
          pushData.answerContent = newfBInput.toString();
        }
        this.$emit('pushData', pushData)
      }else{
        let pushData = {};
        pushData.id = this.exercisesInfo.id;
        //判断是否全为空格
        let newfBInput = [];
        let emptyNum = 0;
        for (let i = 0; i < val.length; i++) {
          let fb = val[i];
          if (typeof (fb) != 'undefined' && fb.trim() != "") {
            newfBInput.push(fb)
          } else {
            newfBInput.push("")
            emptyNum++;
          }
        }
        if (emptyNum != 0 && emptyNum == (newfBInput.length)) {
          pushData.answerContent = null;
        } else {
          let inputNumber = this.useFormatExercisesInfo.exercisesBody;
          if (newfBInput.length < inputNumber) {
            for (let i = 0; i < (inputNumber - (newfBInput.length)); i++) {
              newfBInput.push("");
            }
          }
          pushData.answerContent = newfBInput.toString();
        }
        pushData.exercisesListIndex = this.exercisesListIndex;
        pushData.exercisesType = "FB";
        this.$emit('pushData', pushData)
      }

    },
    resultEvaluate(val,oldVal){
      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.resultEvaluate=val
      this.$emit('evaluateData', this.itemObj)
    },
    resultScore(val,oldVal){
      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.resultScore=val
      this.$emit('evaluateData', this.itemObj)
    },
    mResult(val,oldVal){
      this.itemObj=this.useFormatExercisesInfo;
      this.itemObj.mResult=val
      this.$emit('evaluateData', this.itemObj)
    },
  }
}
</script>
<style scoped>
.textColorError {
  color: red;
  border: 1px solid red;
}
.exercisesScoreMR{
  margin: 0 12px;
}
.teacherPGScore{
  display: flex;
  align-items: center;
}
.teacherPGScore>p{
  width: 100px;
  color: red;
  margin: 14px 0;
}
</style>
