<template>
    <!-- 正确答案与解析 -->
    <div style="margin-top:20px" >
        <el-card shadow="always">
            <div slot="header" >
                <span>答案与解析</span>
            </div>
          <div>

            <!-- 选择题-->
            <div v-if="exercisesInfo.exercisesType=='SC' || exercisesInfo.exercisesType=='MC'">
              <p class="analysis">
                <span></span>
                <span>正确答案</span>
              </p>
              <div v-for="(item, index) in exercisesInfo.questionOption"
                   :key="index"
                   class="chooseSpan">
                <div v-if="exercisesInfo.correctAnswerArr.includes( index+1+'' )" class="selectColor">
                  <span class="quanWork">
                    {{getLetter(index)}}
                  </span>
                  <span>
                    ：{{ item }}
                  </span>
                </div>
              </div>
            </div>
            <!-- 判断题-->
            <div v-if="exercisesInfo.exercisesType=='TF'" >
            <p class="analysis">
              <span></span>
              <span>答案</span>
            </p>
              <span v-if="exercisesInfo.correctAnswer=='T'">正确</span>
              <span v-if="exercisesInfo.correctAnswer=='F'">错误</span>
            </div>
          </div>
          <!-- 解析 -->
          <div v-if="exercisesInfo.analysis">
            <p class="analysis">
              <span></span>
              <span>解析</span>
            </p>
            <p>
              {{ exercisesInfo.analysis }}
            </p>
          </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name:"rightAndAnalysis",
    props: {
        //习题数据
        exercisesInfo:{
            type:Object,
            required: true,
            default(){
                return {}
            },
        }
    },
    data(){
        return{
            chooseRadio:"",
        }
    },
    created(){

    },
    components:{

    },
    computed: {

    },
    updated() {

    },
  mounted() {
  },
  methods:{
      getLetter(index){
        return String.fromCharCode(65 + index)
      },
    }
}
</script>
